import React from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { LuInfo } from "react-icons/lu";

const Tooltip = ({ id, place, html, className='' }) => {
  return (
    <a data-tooltip-id={id} data-tooltip-html={html} className={className}>
      <LuInfo className="ml-4 text-xl text-gray-400 cursor-pointer" />
      <ReactTooltip id={id} place={place} />
    </a>
  );
};

export default Tooltip;