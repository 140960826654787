import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { 
  LuX, 
  LuHome, 
  LuPen, 
  LuStickyNote, 
  LuClipboardCheck, 
  LuFileAudio, 
  LuUsers, 
  LuScrollText, 
  LuMap, 
  LuReplaceAll, 
  LuAxe,
  LuDoorOpen,
  LuMessagesSquare
 } from "react-icons/lu";

import { getBasePath } from "../utils/routing";

const MobileMenu = ({ setShowMobileMenu }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  }

  return (
    <div
      className='absolute z-50 h-screen w-full bg-white overflow-hidden'
    >
      <div className="flex w-full py-3 shadow-sm">
        <div className="flex w-full ml-8">
          {/* TODO: These urls should switch to the main page if user is logged in. */}
          <Link to="/home" className="bg-white rounded-lg mr-3">
            <img src="/logo192.png" alt="Logo" className="h-11 w-11 p-1" />
          </Link>
          <Link
            className="text-sm font-bold font-play leading-relaxed inline-block py-2.5 whitespace-nowrap text-[#1A936F]"
            to="/home"
          >
            ShipWriter
          </Link>
        </div>
        <div className="hidden sm:flex mr-8 space-x-3">
          <Link
            className={`text-sm font-roboto leading-relaxed inline-block px-5 py-2 my-auto whitespace-nowrap rounded-full border-[1px] border-gray-300 hover:border-gray-500 transition-all`}
            to="/login"
            onClick={handleLogout}
          >
            Sign out
          </Link>
        </div>

        {/* Mobile menu header */}
        <div className="sm:hidden flex w-full justify-end mr-4">
          <button
            className="text-3xl border-[1px] border-gray-100 rounded-lg p-2"
            onClick={() => setShowMobileMenu(false)}
          >
            <LuX />
          </button>
        </div>
      </div>

      <div className="pt-1 pr-6">
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuHome />} text={"Home"} link={"/home"}  />
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuPen />} text={"My Writing"} link={"/writing"}  />
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuMessagesSquare />} text={"Feedback"} link={"/feedback"}  />
        {/* <NavItem setMenuVisible={setShowMobileMenu} icon={<LuStickyNote />} text={"Notes"} link={"/notes"}  />
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuClipboardCheck />} text={"TODO Items"} link={"/todos"}  />
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuFileAudio />} text={"Writing Audio"} link={"/settings"}  /> */}
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuUsers />} text={"Characters"} link={"/characters"}  />
        {/* <NavItem setMenuVisible={setShowMobileMenu} icon={<LuScrollText />} text={"Sentences"} link={"/sentences"}  />
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuMap />} text={"Word Maps"} link={"/words"}  /> */}
        {/* <NavItem setMenuVisible={setShowMobileMenu} icon={<LuReplaceAll />} text={"Beta Reads"} link={"/beta-read"}  /> */}
        {/* <NavItem setMenuVisible={setShowMobileMenu} icon={<LuAxe />} text={"Manuscript"} link={"/manuscript"}  /> */}
        <NavItem setMenuVisible={setShowMobileMenu} icon={<LuDoorOpen />} text={"Logout"} link={"/feedback"} onClick={handleLogout} classes="fixed bottom-4 right-6" />
      </div>
    </div>
  )
}

const NavItem = ({ icon, text, link, setMenuVisible, classes='', onClick=() => {} }) => {
  const { pathname } = useLocation();

  const handleClick = () => {
    setMenuVisible(false);
    onClick();
  }

  return (
    <div className={`pb-1 mr-0 ${(getBasePath(pathname) === getBasePath(link)) ? 'text-[#1A936F]' : 'text-black'} ${classes}`}>
      <Link className="flex justify-end" to={link} onClick={handleClick}>
        <h1 className='text-2xl overflow-hidden whitespace-nowrap transition-all z-0 p-2 pt-[0.7rem] pl-2'>{text}</h1>
        <div className="bg-white text-2xl z-10 p-4">{icon}</div>
      </Link>
    </div>
  );
}

export default MobileMenu;