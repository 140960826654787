import { useEffect, useMemo, useRef } from 'react';
import { debounce } from 'lodash';

const DEBOUNCE_TIME = 1000;

export const useDebounce = (callback) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      // @ts-ignore - TS doesn't know that ref.current is a function
      ref.current?.();
    };

    return debounce(func, DEBOUNCE_TIME);
  }, []);

  return debouncedCallback;
};