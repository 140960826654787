import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

import config from "../../config";

const Recorder = ({ voiceUrl='' }) => {
  const [audioBlob, setAudioBlob] = useState(null);
  const { id } = useParams();
  const recorderControls = useVoiceVisualizer();
  const {
    setPreloadedAudioBlob,
    recordedBlob,
    error
  } = recorderControls;

  const loadAudioRecording = async () => {
    try {
      const res = await fetch(voiceUrl);

      if (!res.ok) {
        throw new Error("Failed to load audio");
      }

      const resBody = await res.blob();
      console.log(resBody);
      setAudioBlob(resBody);
      setPreloadedAudioBlob(resBody);
    } catch (err) {
      console.error(err);
    }
  }

  const uploadAudioRecording = async (audio) => {
    console.log(audio);
    console.log(id);

    const formData = new FormData();
    formData.append("voice", audio, "voice.webm");

    try {
      // Not using backendRequest here because we need to send a FormData object
      const res = await fetch(`${config.apiUrl}/api/v1/characters/${id}/voice`, {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error("Failed to upload audio");
      }

      const resBody = await res.json();
      console.log(resBody);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    if (!voiceUrl) return;

    loadAudioRecording();
  }, [voiceUrl]);

  useEffect(() => {
    if (!recordedBlob) return;

    uploadAudioRecording(recordedBlob);
  }, [recordedBlob, error]);

  return (
    <VoiceVisualizer
      controls={recorderControls}
      mainBarColor="#1A936F"
      secondaryBarColor="#114B5F"
      defaultAudioWaveIconColor="#1A936F"
      defaultMicrophoneIconColor="#1A936F" />
  );
}

export default Recorder;