import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { LuMessageSquareDashed } from "react-icons/lu";

import BetaReadCard from "./BetaReadCard";

import { getUserIdFromToken } from "../../utils/token";
import backendRequest from "../../utils/api";

const BetaReadList = () => {
  const [betaReads, setBetaReads] = useState([]);
  const [userId, setUserId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleNewShare = () => {
    console.log("New Share");
  }

  const fetchBetaReads = async () => {
    // const data = await backendRequest('GET', `/api/v1/beta-reads`);
    // setBetaReads(data);
  }

  useEffect(() => {
    const userId = getUserIdFromToken();
    setUserId(userId);

    fetchBetaReads();
  }, []);

  return (
    <div className="h-full min-h-screen w-full bg-gray-100">

    <Tabs className='pt-8'>
        <TabList className={'flex rounded-full justify-center p-1 pb-4 cursor-pointer'}>
          <Tab
            className={`rounded-l-full py-2 pr-3 pl-4 border-[1px] outline-none ${selectedIndex === 0 ? 'bg-[#1A936F] text-white border-[#1A936F]' : 'bg-white hover:bg-gray-100 border-gray-200'}`}
            onClick={() => setSelectedIndex(0)}
            >Submitted</Tab>
          <Tab
            className={`flex rounded-r-full py-2 pl-3 pr-4 border-[1px] border-l-0 outline-none ${selectedIndex === 1 ? 'bg-[#1A936F] text-white border-[#1A936F]' : 'bg-white hover:bg-gray-100 border-gray-200'}`}
            onClick={() => setSelectedIndex(1)}
            >Received</Tab>
        </TabList>

        <TabPanel>
          <div className="w-full flex justify-end sm:justify-normal">
            <button 
              onClick={handleNewShare}
              className="mx-8 bg-[#1A936F] text-gray-100 rounded-lg">
              <div className="flex">
                <span className="p-1.5">
                  <LuMessageSquareDashed className="text-lg" />
                  </span> 
                <h1 className="text-lg pr-2">New Beta Review Request</h1>
              </div>
            </button>
          </div>
          <div className="p-4 pt-1 flex flex-row flex-wrap">
            {betaReads.map((beta) => (
              <BetaReadCard key={beta._id} betaRead={beta} userId={userId} />
            ))}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="p-4 pt-1 flex flex-row flex-wrap">
            {betaReads.map((beta) => (
              <BetaReadCard key={beta._id} betaRead={beta} userId={userId} />
            ))}
          </div>
        </TabPanel>
    </Tabs>

    </div>
  );
};

export default BetaReadList;