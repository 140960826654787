import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import CountUp from 'react-countup';

import backendRequest from '../utils/api';

// Perhaps last two weeks is too much data to display in a bar chart
// const buildLastTwoWeeks = () => {
//   const today = new Date();
//   const lastTwoWeeks = [];
//   for (let i = 0; i < 14; i++) {
//     const date = new Date(today);
//     date.setDate(today.getDate() - i);
//     const dayArr = date.toISOString().split('T')[0].split('-');
//     lastTwoWeeks.push(`${dayArr[1]}-${dayArr[2]}`);
//   }
//   return lastTwoWeeks;
// }

const buildLastWeek = () => {
  const today = new Date();
  const lastWeek = [];
  for (let i = 0; i < 7; i++) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    const dayArr = date.toISOString().split('T')[0].split('-');
    lastWeek.push(`${dayArr[1]}-${dayArr[2]}`);
  }
  return lastWeek;
}

// Ugly to use a class here, but ApexCharts doesn't support functional components
const WordCount = () => {
  const [wordsToday, setWordsToday] = useState(0);
  const [wordsLastTwoWeeks, setWordsLastTwoWeeks] = useState([]);

  // const lastTwoWeeks = buildLastTwoWeeks();
  const lastWeek = buildLastWeek();

  const loadWordMetrics = async () => {
    // Returns a key-value pair of the words written for the 
    // last two weeks
    const data = await backendRequest('GET', '/api/v1/writing/metrics/count');

    const metricsArr = lastWeek.map((date) => {
      return data[date] || 0;
    });

    setWordsLastTwoWeeks(metricsArr);

    setWordsToday(data[lastWeek[0]]);
  }

  useEffect(() => {
    loadWordMetrics();
  }, []);

  // Define the chart options
  const options = {
    chart: {
      id: 'basic-bar'
    },
    width: 'auto',
    xaxis: {
      categories: buildLastWeek()
    },
    title: {
      text: 'Last Two Weeks',
      align: "left" as any,
      style: {
        fontSize: '20px',
        fontFamily: 'Roboto, Arial, sans-serif',
      }
    },
    dataLabels: {
      style: {
        fontSize: window.innerWidth > 470 ? '15px' : '11px',
        fontFamily: 'Roboto, Arial, sans-serif',
      }
    },
  };

  // Define the chart series (data)
  const series = [
    {
      name: 'Word Metrics',
      data: wordsLastTwoWeeks
    }
  ];

  // Assuming the maximum value for wordsToday is 99999, adjust as needed
  const maxDigits = 5;
  const maxWidth = `${maxDigits}ch`; // 'ch' unit represents the width of the "0" character in the current font

  return (
    <div className='flex flex-col md:flex-row rounded-xl border-[1px] border-gray-100 shadow-md max-w-screen-xl mx-auto'>
      <div className='flex flex-col mx-auto md:px-36 md:pr-24'>
        <h1 className='text-xl text-center font-roboto font-bold mt-5 md:mt-[2px] text-[#263238]'>Today's Words</h1>
        <p className='text-center text-8xl my-6 md:my-auto' style={{ width: maxWidth }}>
          <CountUp end={wordsToday} duration={(wordsToday > 100) ? 0.5 : 1.5} />
        </p>
      </div>
      <div className='hidden md:block border-l-[1px] border-l-gray-300 mx-10 my-5'></div>
      <div className="grow bar-chart">
        <Chart options={options} series={series} type='bar' />
      </div>
    </div>
  );
}

export default WordCount;

//       <div className="bg-white shadow-md rounded-lg p-6 m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
//         <Chart options={this.state.options} series={this.state.series} type="bar" height={350} />
//       </div>
//     );
//   }
// }