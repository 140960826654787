import React, { useState } from "react";
import Tooltip from "../../components/Tooltip";
import { LuCheck } from "react-icons/lu";

const VleesForm = ({ vlees, setVlees }) => {
  const [showAddSecret, setShowAddSecret] = useState(false);
  const [newSecretValue, setNewSecretValue] = useState("");

  const handleAddSecret = () => {
    setVlees({ ...vlees, secrets: [...vlees.secrets, newSecretValue] });

    setNewSecretValue("");
    setShowAddSecret(false);
  }

  const handleSecretChange = (secret, index) => {
    vlees.secrets[index] = secret;
    setVlees(vlees);
  }

  const handleVleesChange = (key, value) => {
    setVlees({ ...vlees, [key]: value });
  }

  return (
    <div
      className="p-5 pt-20"
    >
      <div className="my-auto">
        <div className="flex justify-center pb-10">
          <h1 className="text-2xl font-bold">Character Vlees</h1>
          <Tooltip id='vlees-tt' place='bottom' className="mt-[4px]"
            html="Vlees is a Dutch word that means 'meat'.It is used<br />in the context of roleplaying games to describe the<br />core of a character. Vlees is what makes a character<br />unique and interesting. It is the character's essence,<br />their soul. Vlees is what drives a character to act<br />the way they do, to make the choices they make,<br />and what makes them jump off the page at you.<br /><br />These should be no longer than a sentence or two." />
        </div>
        <label>
          <span className="px-4 flex justify-between">
            Want
            <Tooltip id='want-tt' place='left' className="ml-[4px]"
              html='What does your character want? What are they<br />driven to achieve? What is their ultimate goal?<br /><br />In "Of Mice and Men" George wants Lennie to live.' />
          </span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="want"
            value={vlees.want || ""}
            onChange={(e) => handleVleesChange('want', e.target.value)}
          />
        </label>
        <label>
          <span className="px-4 flex justify-between">
            Need
            <Tooltip id='need-tt' place='left' className="ml-[4px]"
              html='What does your character need? What is the one<br />thing that they require to be truly happy?<br /><br />Here, George needs to kill Lennie to save him<br />from suffering.' />
          </span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="need"
            value={vlees.need || ""}
            onChange={(e) => handleVleesChange('need', e.target.value)}
          />
        </label>
        <label>
          <span className="px-4 flex justify-between">
            Drive
            <Tooltip id='drive-tt' place='left' className="ml-[4px]"
              html='What drives your character? This is the "Why?"<br />to the character Want. This is why they get<br />out of bed in the morning.<br /><br />George is driven by hope for a better life and his<br />sense of responsibility for Lennie.' />
          </span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="drive"
            value={vlees.drive || ""}
            onChange={(e) => handleVleesChange('drive', e.target.value)}
          />
        </label>
        <label>
          <span className="px-4 flex justify-between">
            Flaw
            <Tooltip id='flaw-tt' place='left' className="ml-[4px]"
              html="What is your character's flaw? What is the one<br />thing that holds them back, that makes them<br />human?<br /><br />George's flaw is pragmatism, and he gives into<br />this flaw when he kills Lennie, seeing what is<br />in store for him." />
          </span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="flaw"
            value={vlees.flaw || ""}
            onChange={(e) => handleVleesChange('flaw', e.target.value)}
          />
        </label>
        <label>
          <span className="px-4 flex justify-between">
            Strength
            <Tooltip id='strength-tt' place='left' className="ml-[4px]"
              html="What is your character's strength? What is the one<br />thing that makes them stand out, that makes them<br />unique?" />
          </span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="strength"
            value={vlees.strength || ""}
            onChange={(e) => handleVleesChange('strength', e.target.value)}
          />
        </label>
        <label>
          <span className="px-4 flex justify-between">
            Cost
            <Tooltip id='cost-tt' place='left' className="ml-[4px]"
              html="What is the cost of your character's actions? What<br />is the one thing that they have to give up in order<br />to achieve their goal?" />
          </span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="cost"
            value={vlees.cost || ""}
            onChange={(e) => handleVleesChange('cost', e.target.value)}
          />
        </label>
        <div className="py-5 px-4 flex justify-between">
          <h1 className="text-lg font-bold">Character Secrets</h1>
          <Tooltip id='secrets-tt' place='left' className="mt-[4px]"
            html="Secrets are the hidden truths of your character.<br />They can have several secrets of different kinds.<br />Play with some of the following secrets:<br /><br />- One open secret.<br />- One no one knows.<br />- One could get out without damage.<br />- One is only known by <em>one</em> other person.<br />- One the character wants to tell people.<br />- One would ruin their life if it got out." />
        </div>
        {vlees.secrets && vlees.secrets.map((secret, index) => (
          <label key={index}>
            <span className="pl-4">Secret {index + 1}</span>
            <input
              className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
              required
              type="text"
              value={secret}
              onChange={(e) => handleSecretChange(e.target.value, index)}
            />
          </label>
        ))}

        {showAddSecret && (
          <form 
            className="grid grid-cols-1 md:grid-cols-5 gap-4 w-full"
            onSubmit={handleAddSecret}>
            <label className="md:col-span-4">
              <span className="pl-4">New Secret</span>
              <input
                className="md:mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                required
                type="text"
                name="newSecret"
                value={newSecretValue}
                onChange={(e) => setNewSecretValue(e.target.value)}
              />
            </label>
            <button
              className="py-2 px-5 mt-[1.67rem] max-h-11 bg-[#1A936F] text-white font-bold text-center rounded-full transition-all"
              type="submit"
            >
              <LuCheck className="mx-auto text-3xl" />
            </button>
          </form>
        )}

        {!showAddSecret && <div className="flex w-full">
          <button
            className="py-2 px-5 mt-5 mx-auto bg-[#1A936F] text-white font-bold rounded-full transition-all"
            onClick={() => setShowAddSecret(true)}
          >
            Add Character Secret
          </button>
        </div>}
      </div>
    </div>
  )
};

export default VleesForm;