import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LuSettings } from "react-icons/lu";

import WordCount from "../../components/WordCount";
import WordHeatmap from "../../components/WordHeatmap";
import Modal from "../../components/Modal";

import backendRequest from "../../utils/api";

const Home = () => {
  const [user, setUser] = useState<any>({});
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const loadUser = async () => {
    const data = await backendRequest('GET', '/api/v1/users/me');
    console.log(data);
    setUser(data);
  }

  const updateUser = async () => {
    const data = await backendRequest('PUT', '/api/v1/users/goal', {
      goal: user.goal
    });
    console.log(data);
    window.location.href = '/home'; // Refresh the page
  }

  const updateUserGoal = async (e) => {
    e.preventDefault();
    setUser({ ...user, goal: parseInt(e.target.value) });
  }


  const handleModalClose = () => {
    updateUser();
    setIsOpen(false);
  }

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }

    loadUser();
  }, []);

  return (
    <>
      <div className="flex justify-between p-4">
        <h1 className="text-2xl font-bold">Home</h1>
        <button className="text-2xl mr-6 mt-1.5" onClick={() => setIsOpen(true)}>
          <LuSettings className="text-2xl" />
        </button>
      </div>
      <div className="p-4">
        <WordCount />
      </div>
      {user && user.goal !== 0 && <div className="p-4">
        <WordHeatmap goal={user.goal} />
      </div>}
      <Modal
        isOpen={isOpen}
        hasCloseBtn={true}
        onClose={handleModalClose}>
        <div className="flex flex-col px-2 pb-5 space-y-6">
          <h1 className="text-2xl font-bold">Settings</h1>
          <label>
            <span className="pl-4">Daily Writing Goal</span>
            <input
              className="mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
              required
              type="number"
              name="goal"
              value={user.goal}
              onChange={(e) => updateUserGoal(e)}
            />
          </label>
        </div>
      </Modal>
    </>
  )
}

export default Home;