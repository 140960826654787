import React from "react";
import { Link } from "react-router-dom";

const CharacterCard = ({ character }) => {
  return (
    // <div className="h-1/4 p-2 basis-1/2 sm:basis-1/2 md:basis-1/3 lg:basis-1/4 bg-white rounded-lg">
    <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
      <Link to={`/characters/${character._id}`} 
        className="w-full h-full">
        <div className="flex flex-col px-6 align-top">
          <h1 className="text-black-500 font-semibold text-xl text-left">
            {character.name}
          </h1>
          {/* <p className="text-black-500 text-left break-words">
            {character.details.slice(0, 150)}...
          </p> */}
        </div>
      </Link>
    </div>
  );
};

export default CharacterCard;