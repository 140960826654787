import React from "react";
import { LuMessageSquare } from "react-icons/lu";

const BetaReadCard = ({ betaRead, userId }) => {

  const resolveStatusClasses = (status) => {
    switch (status) {
      case "Completed":
        return "bg-[#1A936F] text-white border-[#1A936F]";
      case "In Progress":
        return "bg-[#F9A826] text-white border-[#F9A826]";
      case "Not Started":
        return "bg-[#F87171] text-white border-[#F87171]";
      default: // Not accepted
        return "bg-white hover:bg-gray-100 border-gray-200";
    }
  }

  return (
    <div className="bg-white shadow-md rounded-lg p-4 sm:p-6 m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
      <div className="flex flex-col px-6 align-top">
        <h1 className="text-black-500 font-semibold text-xl text-left">
          {betaRead.title}
        </h1>
        <p className="text-black-500 text-left break-words">
          Reviewer: {betaRead.reviewerId === userId ? 'Me' : betaRead.reviewerUsername}
        </p>
        <div className="flex flex-row justify-between">
          <p className={`text-left ${resolveStatusClasses(betaRead.status)}`}>
            {betaRead.status}
          </p>
          <p className="text-black-500 text-right">
            {/* totalComments is a computed field */}
            <LuMessageSquare /> {betaRead.totalComments} 
          </p>
        </div>
      </div>
    </div>
  );
}

export default BetaReadCard;