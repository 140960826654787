import React, { useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

import Avatar from "./Avatar";
import Recorder from "./Recorder";

import Tooltip from "../../components/Tooltip"

const DesignForm = ({ voiceUrl, imageUrl, setImageUrl }) => {
  return (
    <div
      className="p-5 pt-20"
    >
      <div className="my-auto">
        <div className="flex justify-center pb-10">
          <h1 className="text-2xl font-bold">Character Design</h1>
        </div>
        <div className="flex pt-4">
          <h1 className="text-lg font-bold flex">
            Image
            <Tooltip id='image-tt' place='bottom' className="mt-[4px]"
              html="Upload an image of your character. This could be a<br />sketch, a painting, a photograph, or anything else<br />that represents your character visually." />
          </h1>
        </div>
        <Avatar imageUrl={imageUrl} setImageUrl={setImageUrl} />
        <div className="flex pt-4">
          <h1 className="text-lg font-bold flex">
            Voice Recording
            <Tooltip id='voice-tt' place='bottom' className="mt-[4px]"
              html="Record an audio clip of your character speaking. <br />Great for recalling an accent or dialogue line!" />
          </h1>
        </div>
        <Recorder voiceUrl={voiceUrl} />
      </div>
    </div>
  )
};

export default DesignForm;