import React from "react";

const BetaRead = () => {
  return (
    <div>
      <h1>Beta Read</h1>
    </div>
  );
};

export default BetaRead;