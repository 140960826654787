import React from "react";

const FeatureListItem = ({ title, description, icon }) => {
  return (
    <div className="flex items-center justify-start pl-10">
      <div className="flex items-center justify-start w-20 h-20 pr-4">
        {icon}
      </div>
      <div className="flex flex-col">
        <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
        <p className="text-left text-white">{description}</p>
      </div>
    </div>
  );
}

export default FeatureListItem;