import * as React from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { LuHome, LuPen, LuUsers, LuStickyNote, LuClipboardCheck, LuFileAudio, LuMap, LuScrollText, LuReplaceAll, LuAxe, LuMessagesSquare } from "react-icons/lu";

import { getBasePath } from "../utils/routing";

const Sidebar = () => {
  const [hovering, setHovering] = useState(false);

  return (
    <div className="hidden sm:block fixed top-[4.28rem] bg-white h-full w-[4.6rem] border-r-[1px] border-t-[1px] hover:w-[15rem] transition-all z-50"
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}>
      
      <NavItem icon={<LuHome />} text={"Home"} link={"/home"} hovering={hovering} />
      <NavItem icon={<LuPen />} text={"My Writing"} link={"/writing"} hovering={hovering} />
      {/* <NavItem icon={<LuStickyNote />} text={"Notes"} link={"/notes"} hovering={hovering} />
      <NavItem icon={<LuClipboardCheck />} text={"TODO Items"} link={"/todos"} hovering={hovering} />
      <NavItem icon={<LuFileAudio />} text={"Writing Audio"} link={"/settings"} hovering={hovering} /> */}
      <NavItem icon={<LuUsers />} text={"Characters"} link={"/characters"} hovering={hovering} />
      {/* <NavItem icon={<LuScrollText />} text={"Sentences"} link={"/sentences"} hovering={hovering} />
      <NavItem icon={<LuMap />} text={"Word Maps"} link={"/words"} hovering={hovering} /> */}
      {/* <NavItem icon={<LuReplaceAll />} text={"Beta Reads"} link={"/beta-read"} hovering={hovering} /> */}
      {/* <NavItem icon={<LuAxe />} text={"Manuscript"} link={"/manuscript"} hovering={hovering} /> */}
      {/* Gem should be reserved for premium
      <NavItem icon={<LuMessagesSquare />} text={"Feedback"} link={"/feedback"} hovering={hovering} classes="fixed bottom-0" />
      {/* <NavItem icon={<LuPen />} text={"Thesaurus"} link={"/thesaurus"} hovering={hovering} /> */}
      {/* <NavItem icon={<LuPen />} text={"Publishing"} link={"/publishing"} hovering={hovering} /> */}
      {/* <NavItem icon={<LuPen />} text={"Marketing"} link={"/marketing"} hovering={hovering} /> */}
      {/* <NavItem icon={<LuPen />} text={"Sales"} link={"/sales"} hovering={hovering} /> */}
      {/* <NavItem icon={<LuPen />} text={"Reviews"} link={"/reviews"} hovering={hovering} /> */}
    </div>
  );
}

const NavItem = ({ icon, text, link, hovering, classes='' }) => {
  const { pathname } = useLocation();

  return (
    <div className={`pb-0.5 ${(getBasePath(pathname) === getBasePath(link)) ? 'text-[#1A936F]' : 'text-black'} ${classes}`}>
      <Link className="flex" to={link}>
        <div className="bg-white text-2xl z-10 p-6">{icon}</div>
        <h1 className={`text-xl overflow-hidden whitespace-nowrap transition-all z-0 p-5 pt-[1.35rem] pl-2 ${
              hovering ? "translate-x-0" : "translate-x-[-100%]"
            }`}>{text}</h1>
      </Link>
    </div>
  );
}

export default Sidebar;
