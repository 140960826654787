import config from "../config";

const backendRequest = async (method: string, path: string, data?: any) => {
  const response = await fetch(`${config.apiUrl}${path}`, {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token'),
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    // Redirect to login if unauthorized
    if (response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    }

    throw new Error(`Failed to ${method} ${path}`);
  }

  return await response.json();
}

export default backendRequest;