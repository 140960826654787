import * as React from "react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LuHome } from "react-icons/lu";

import backendRequest from "../../utils/api";

import config from "../../config";

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const registerUser = async () => {
    const data = await backendRequest('POST', '/api/v1/auth/register', { 
      email,
      username, 
      password 
    });
    console.log(data);
    localStorage.setItem("token", data.token);
    return data;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      registerUser();
      const next = searchParams.get("next");
      if (next) {
        navigate(next);
      }
      else {
        navigate("/home");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="absolute left-[1%] hidden sm:block">
        <div className="bg-white rounded-full border-[1px] border-gray-100 p-4 ml-10 mt-5 shadow-lg">
          <h1 className="font-play font-bold text-2xl">ShipWriter 🍃🌿🌱</h1>
        </div>
      </div>
      <div className="flex flex-row h-screen">
        <div className="hidden sm:grow sm:flex">
          <div className="m-auto">
            <h1 className="text-4xl text-center pb-24">Let's level up your <span className="font-play text-[#1A936F]">writing</span></h1>
            <img src="./logo512.png" alt="ShipWriter Logo"></img>
          </div>
        </div>
        <div className="grow sm:flex-none w-1/3 background-white sm:shadow-lg sm:shadow-black mr-0">
        <div className="sm:hidden flex pb-5 pt-10">
            <h1 className="text-2xl font-play font-bold m-auto">ShipWriter</h1>
          </div>
        <div className="flex flex-col justify-center">
          <div className="flex sm:hidden justify-center pb-3">
            <img className="w-1/3" src="./logo192.png" alt="ShipWriter Logo"></img>
          </div>
          <form
            className="flex flex-col p-5 pt-20"
            onSubmit={handleSubmit}
          >
            <div className="my-auto">
              <div className="flex justify-center">
                <h1 className="text-2xl font-play pb-10">Register</h1>
              </div>
              <label>
                <span className="pl-4">Email</span>
                <input
                  className="mb-3 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </label>
              <label>
                <span className="pl-4">Username</span>
                <input
                  className="mb-3 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                  type="text"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </label>
              <label>
                <span className="pl-4">Password</span>
                <input
                  className="my-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </label>
              <div className="flex justify-center">
                <button type="submit" className="py-2 px-5 mt-5 text-[#1A936F] font-bold border border-white hover:border-gray-200 rounded-full transition-all">Submit</button>
              </div>

              <div className="relative bottom-0 w-full flex">
                <button className="py-2 px-5 mt-7 mx-auto font-bold border border-gray-300 hover:border-gray-500 sm:border-white sm:hover:border-gray-200 rounded-full transition-all" onClick={() => navigate('/')}><LuHome /></button>
              </div>
            </div>
          </form>
        </div>
        </div>
      </div>
    </>
  )
}

export default Register;