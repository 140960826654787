import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { LuCheck, LuPlus } from 'react-icons/lu';

import LandingSection from '../../components/LandingSection';

const faqs = [
  {
    question: 'What is audiobook conversion?',
    answer: 'Audiobook conversion is the process of converting your written text into an audio format. This is useful for authors who want to share their work with beta readers or for those who want to listen to their work read aloud.\n\nListening to work read aloud can help you catch errors in your writing that you might not have noticed otherwise. It can also help you get a better sense of the pacing and flow of your story.'
  },
  {
    question: 'What is a character sheet?',
    answer: 'A character sheet is a document that contains all of the information you need to know about a character in your story. This can include details about their appearance, personality, background, and more. Character sheets are a useful tool for keeping track of your characters and ensuring that they are consistent throughout your story. \n\nWith Shipwriter we detect when you reference a character in your stories and automatically create a link to their character sheet. This makes it easy to keep track of your characters and access their information quickly.'
  },
  {
    question: 'What is a beta reader?',
    answer: 'A beta reader is someone who reads your work before it is published and provides feedback on it. Beta readers can help you identify areas where your story is strong and areas where it could be improved. They can provide valuable insights that can help you make your story the best it can be. \n\nWith Shipwriter you can share your work with beta readers directly from the platform. This makes it easy to get feedback on your writing and make improvements based on that feedback.'
  },
  {
    question: 'Can I use ShipWriter for things other than creative writing?',
    answer: 'Yes! ShipWriter is a versatile tool that can be used for a wide range of writing projects. Whether you are working on a novel, a screenplay, a blog post, a DnD campaign, a YouTube script, or anything else, ShipWriter can help you organize your ideas, develop your characters, and improve your writing. \n\nOur platform is designed to be flexible and adaptable, so you can use it in whatever way works best for you.'
  },
  {
    question: 'I use ProWritingAid or Grammarly. Do I need to switch?',
    answer: 'No, you do not need to switch. ShipWriter is designed to work alongside other writing tools like ProWritingAid and Grammarly. ShipWriter focuses on helping you organize your ideas, develop your characters, and improve your writing style.\n\nProWritingAid and Grammarly on the other hand, help you improve your syntax to follow the writing rules.\n\nYou can use ShipWriter in conjunction with other writing tools installed as browser extensions to get the best of both worlds. ShipWriter is designed to be a flexible and adaptable tool that can be used in whatever way works best for you.'
  },
  {
    question: 'Do you use AI?',
    answer: 'Yes, we use AI ethically on our platform. We help you use the power of AI without letting it do the writing for you.\n\nYour work stays yours.\n\nOur AI features are focused on text-to-speech generation, research questions you have while writing, and expanding your writing style and voice.'
  },
  {
    question: 'What happens to my data?',
    answer: 'We take your privacy and data security seriously. We do not share your data with third parties without your permission, and we use industry-standard security practices to keep your data safe.\n\nYour data is yours and we will not use it for the purpose of training algorithms. We believe that you should have control over your data and we work hard to make sure that it is safe and secure.'
  },
  {
    question: 'What is the difference between the Creative and Industry plans?',
    answer: 'The Creative plan is designed for creatives and authors who want to improve their writing skills and share their work with others to improve and break into the industry of writing.\n\nThe Industry plan is designed for novelists, editors, and publishers who want to simplify and improve their workflows. For those who are serious about getting published, there\'s a manuscript conversion feature that will help you get your work ready for publication.\n\nIndustry is truly the plan for those who are serious about publication and reaching the highest number of readers.'
  },
  {
    question: 'What are my payment options?',
    answer: 'You can choose to pay monthly or annually. If you choose to pay annually, you will receive a discount equivalent to two months free!'
  },
  {
    question: 'What if I want to cancel my subscription?',
    answer: 'You can cancel your subscription at any time. If you cancel your subscription, you will still have access to your account until the end of your billing period. After that, your account will be downgraded to the free plan.'
  },
]

const Pricing = () => {
  const [active, setActive] = useState(false);
  const [faqOpen, setFaqOpen] = useState(null);
  // const contentRef = useRef(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   contentRef.current.style.maxHeight = active
  //     ? `${contentRef.current.scrollHeight}px`
  //     : "0px";
  // }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };

  const toggleFaq = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="bg-gray-100 py-12">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-base font-semibold text-[#1A936F] tracking-wide uppercase">Pricing</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Choose Your Plan
            </p>
          </div>
          
          <div className="mt-10 sm:mt-16 lg:mt-24">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {/* Free Plan */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-between border-t-8 border-[#EE6352]">
                <div className="px-6 py-8 grow">
                  <h3 className="text-4xl font-bold text-center text-[#EE6352] pb-6">Basic</h3>
                  <p className="text-md font-bold">For hobbyists and beginners</p>
                  <ul className="mt-4 text-lg text-gray-600 space-y-2">
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />10 stories</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />10 character sheets</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />3 concurrent beta-readers</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />5 minutes audiobook conversion per month</li>
                  </ul>
                </div>
                <h3 className="text-3xl font-medium text-center text-gray-700 bg-white pb-6">Free. Always.</h3>
                <div className="px-6 py-4 bg-gray-50 flex justify-between items-center">
                  <span className="text-lg font-medium leading-6 text-gray-900">Free</span>
                  <button onClick={() => navigate('/register')} className="bg-[#EE6352] text-white px-4 py-2 border-2 border-[#EE6352] rounded-md transition-all hover:shadow-lg">Sign up</button>
                </div>
              </div>
              
              {/* Standard Plan */}
              <div className="bg-gradient-to-r from-indigo-500 via-blue-500 to-emerald-500 shadow-lg rounded-lg overflow-hidden border-t-8 border-[#1A936F] flex flex-col justify-between">
                <div className="px-6 py-8 bg-white grow">
                <h3 className="text-4xl font-bold text-center text-[#1A936F] pb-6">Creative</h3>
                <p className="text-md font-bold">For creatives and authors</p>
                  <ul className="mt-4 text-lg text-gray-600 space-y-2">
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Unlimited writing</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />50 character sheets</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />10 concurrent beta readers</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Image uploads for character pages</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Half a novel of audiobook conversion per month</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Grow your writing voice and style</li>
                  </ul>
                  {/* <p className="mt-4 text-sm text-gray-600">Save with annual billing: $150/year (2 months free)</p> */}
                </div>
                <h3 className="text-5xl font-medium text-center text-gray-700 bg-white pb-6">$15 <span className='text-sm'>/ Mo.</span></h3>
                <div className="px-6 py-4 flex justify-between items-center">
                  <span className="text-lg leading-6 font-medium text-white">$15 / month</span>
                  <button onClick={() => navigate('/purchase/creative')} className="bg-transparent text-white px-4 py-2 border-2 rounded-md transition-all hover:shadow-lg">Buy now</button>
                </div>
              </div>

              {/* Premium Plan */}
              <div className="bg-white shadow-lg rounded-lg overflow-hidden flex flex-col justify-between border-t-8 border-gray-600">
                <div className="px-6 py-8 grow">
                <h3 className="text-4xl font-bold text-center text-gray-700 pb-6">Industry</h3>
                <p className="text-md font-bold">For novelists, editors, and publishers</p>
                  <ul className="mt-4 text-lg text-gray-600 space-y-2">
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Unlimited writing</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Unlimited characters</li> 
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Unlimited beta readers</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Image and voice acting audio upload for characters</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />2 novels' worth of audiobook conversion per month</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Share audiobook versions of your work to beta readers</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Convert to manuscript and EPub</li>
                    <li className='pb-2 flex'><LuCheck className='text-xl mt-1 mr-3 text-emerald-500' />Submit for publication and track status</li>
                  </ul>
                  {/* <p className="mt-4 text-sm text-gray-600">Save with annual billing: $500/year (2 months free)</p> */}
                </div>
                <h3 className="text-5xl font-medium text-center text-gray-700 bg-white pb-6">$50 <span className='text-sm'>/ Mo.</span></h3>
                <div className="px-6 py-4 bg-gray-50 flex justify-between items-center">
                  <span className="text-lg leading-6 font-medium text-gray-900">$50 / month</span>
                  <button onClick={() => navigate('/purchase/industry')} className="bg-gray-600 text-white px-4 py-2 rounded-md transition-all border-2 border-gray-600 hover:shadow-lg">Buy now</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h1 className="text-2xl font-medium pt-10 px-4 text-center">
          Or get 2 months free with an annual subscription
        </h1>
      </div>
      <LandingSection>
        <h1 className="text-4xl font-bold pb-10 text-center">
          Frequently Asked Questions
        </h1>
        <div className='flex flex-col w-full space-y-8'>
          {faqs.map((faq, index) => (
              <div key={index} className="p-2 border border-gray-100 rounded-md shadow-md mx-auto w-full max-w-screen-lg">
                <button
                  onClick={() => toggleFaq(index)}
                  className="w-full text-2xl px-4 py-2 flex justify-between text-gray-900 font-medium focus:outline-none"
                >
                  {faq.question}
                  <LuPlus
                    className={`text-3xl transition-all min-w-8 ${
                      faqOpen === index
                        ? 'mt-0.5 rotate-45 text-black'
                        : 'mt-0.5 text-[#1A936F]'}`
                      }
                    />
                </button>
                {faqOpen === index && (
                  <div className="px-7 pt-3 pb-3 mt-2 mx-3 text-xl text-gray-600 border-t-[1px] border-gray-200">
                    {faq.answer.includes('\n\n') ?
                      faq.answer.split('\n\n').map((paragraph, index) => (
                        <p key={index} className={index < faq.answer.split('\n\n').length ? 'pb-5' : ''}>{paragraph}</p>
                      ))
                    : faq.answer
                    }
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className='pt-16'>
          <button onClick={() => navigate('/purchase/creative')} className="bg-[#1A936F] text-white px-4 py-2 border-2 border-[#1A936F] rounded-md transition-all hover:shadow-lg">
            Register today
          </button>
        </div>
      </LandingSection>
    </>
  );
}

export default Pricing;