export const decodeToken = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  const [, payload] = token.split(".");
  return JSON.parse(atob(payload));
}

export const getUserIdFromToken = () => {
  const data = decodeToken();
  return data.sub;
};