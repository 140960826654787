import React from "react";

type Props = {
  bgClass?: string;
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element) | (() => JSX.Element)[];
}

const LandingSection = ({ bgClass='bg-white', children=<></> }: Props) => {
  return (
    <div className={`flex flex-col items-center justify-center h-full py-36 ${bgClass}`}>
      <>
        {children}
      </>
    </div>
  );
}

export default LandingSection;