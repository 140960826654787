import React, { useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Import the styles

import { ClickableLink, WordCounter, ClickableName, ClickableNameModule } from '../utils/quill-extentions';

// Define custom fonts
// const Font = Quill.import('formats/font');
// (Font as any).whitelist = [
//   'Roboto', 'Arial', 'Courier New', 'Georgia', 'Lucida Sans Unicode', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana'
// ];
// Quill.register(Font as any, true);

Quill.register('formats/link', ClickableLink, true);
Quill.register('modules/wordCounter', WordCounter, true);
Quill.register('formats/clickable-name', ClickableName);
Quill.register('modules/clickableNameModule', ClickableNameModule);

const PaperEditor = ({ 
  content, 
  setContent,
  debouncedSaveChanges,
  placeholder='Write something amazing...',
  isBetaRead=false
}) => {
  const [lastDelta, setLastDelta] = useState(null);
  const quillRef = useRef(null);

  const modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}],
      [{size: []}],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', 'script'],
      [{'list': 'ordered'}, {'list': 'bullet'}, 
       {'indent': '-1'}, {'indent': '+1'}],
      ['code-block', 'link'],
      ['clean']
    ],
    wordCounter: {
      containerId: 'wordCounter'
    },
    clickableNameModule: true
  };

  const formats = [
    'header', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'clickable-name',
  ];

  const handleChange = (content, delta, source, editor) => {
    const fullDelta = editor.getContents();
    setContent(fullDelta);


    if (deltasAreEqual(delta, lastDelta)) {
      return;
    }

    setLastDelta(delta);
    debouncedSaveChanges();
  }

  const deltasAreEqual = (delta1, delta2) => {
    if (delta1 === null || delta2 === null) {
      return false;
    }

    if (delta1.ops.length !== delta2.ops.length) {
      return false;
    }

    // find the detla1 insert if it exists
    let delta1Insert = null;
    for (let i = 0; i < delta1.ops.length; i++) {
      if (delta1.ops[i].insert) {
        delta1Insert = delta1.ops[i].insert;
        break;
      }
    }

    // find the delta2 insert if it exists
    let delta2Insert = null;
    for (let i = 0; i < delta2.ops.length; i++) {
      if (delta2.ops[i].insert) {
        delta2Insert = delta2.ops[i].insert;
        break;
      }
    }

    if (delta1Insert === null || delta2Insert === null) {
      return false;
    }

    return delta1Insert === delta2Insert;
  }

  return (
    <>
      <div className='hidden' id="wordCounter"></div>
      <ReactQuill
        ref={quillRef}
        theme="bubble"
        value={content}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
      />
    </>
  );
}

export default PaperEditor;