import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LuMenu } from "react-icons/lu";

import MobileMenu from "./MobileMenu";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  }

  const handleMobileMenu = (val: boolean) => {
    if (val === true) {
      window.scrollTo(0, 0);
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "auto";
    }

    setShowMobileMenu(val);
  }

  return (
    <>
      <div
        className={`z-10 flex py-3 shadow-sm sticky top-0 bg-white`}
      >
        <div className="flex w-full">
          <div className="flex w-full ml-8">
            {/* TODO: These urls should switch to the main page if user is logged in. */}
            <Link to="/home" className="bg-white rounded-lg mr-3">
              <img src="/logo192.png" alt="Logo" className="h-11 w-11 p-1" />
            </Link>
            <Link
              className="text-sm font-bold font-play leading-relaxed inline-block py-2.5 whitespace-nowrap text-[#1A936F]"
              to="/home"
            >
              ShipWriter
            </Link>
          </div>
          <div className="hidden sm:flex mr-8 space-x-3">
            <Link
              className={`text-sm font-roboto leading-relaxed inline-block px-5 py-2 my-auto whitespace-nowrap rounded-full border-[1px] border-gray-300 hover:border-gray-500 transition-all`}
              to="/login"
              onClick={handleLogout}
            >
              Sign out
            </Link>
          </div>

          {/* Mobile menu header */}
          <div className="sm:hidden flex w-full justify-end mr-4">
            <button
              className="text-3xl border-[1px] border-gray-100 rounded-lg p-2"
              onClick={() => handleMobileMenu(true)}
            >
              <LuMenu />
            </button>
          </div>
        </div>
      </div>
      {showMobileMenu && <MobileMenu setShowMobileMenu={handleMobileMenu} />}
    </>
  );
};

export default Header;