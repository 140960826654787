import * as React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Header from "./components/Header";
import LandingHeader from "./components/LandingHeader";
import Landing from "./pages/landing";
import Pricing from "./pages/pricing";
import Purchase from "./pages/purchase";
import Sidebar from "./components/Sidebar";
import Login from "./pages/login";
import Register from "./pages/register";
import Home from "./pages/home";
import WritingList from "./pages/writing-list";
import Writing from "./pages/writing";
import CharacterList from "./pages/character-list/CharacterList";
import Character from "./pages/character";
import BetaReadList from "./pages/beta-read-list/BetaReadList";
import BetaRead from "./pages/beta-read/BetaRead";

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const hideHeaderPaths = ["/", "/login", "/register", "/pricing", "/purchase/creative", "/purchase/industry"];
  const hideSidebarPaths = ["/", "/login", "/register", "/pricing", "/purchase/creative", "/purchase/industry"];

  const showLandingHeaderPaths = ["/", "/pricing", "/purchase/creative", "/purchase/industry"];

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token && (pathname === "/" || pathname === "/login" || pathname === "/register")) {
      navigate("/home");
    }
  }, [token, navigate]);

  return (
    <div className={`h-full min-h-screen w-full flex flex-col`}>
      {/* Only show the header on non-login screens ml-[4.65rem]  */}
      {hideHeaderPaths.includes(pathname) ? null : 
        <Header />}

      {/* Only show the landing header on landing and pricing pages */}
      {showLandingHeaderPaths.includes(pathname) ? <LandingHeader /> : null}

      {/* Only show the sidebar on non-login screens */}
      {/* <div className="h-full w-full flex flex-row"> */}
        {hideSidebarPaths.includes(pathname) ? null : <Sidebar />}
        
        <div className={`${(!hideSidebarPaths.includes(pathname)) ? 'sm:ml-[4.6rem]' : ''} h-full`}>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/purchase/:type" element={<Purchase />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/home" element={<Home />} />
            <Route path="/writing" element={<WritingList />} />
            <Route path="/writing/:id" element={<Writing />} />
            <Route path="/characters" element={<CharacterList />} />
            <Route path="/characters/:id" element={<Character />} />
            <Route path="/beta-read" element={<BetaReadList />} />
            <Route path="/beta-read/:id" element={<BetaRead />} />
          </Routes>
        </div>
      {/* </div> */}
    </div>
  );
}

export default App;