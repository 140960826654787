import React, { useEffect, useRef } from "react";
import CalHeatmap from 'cal-heatmap';
import 'cal-heatmap/cal-heatmap.css';
// @ts-ignore
import Tooltip from 'cal-heatmap/plugins/Tooltip';
// @ts-ignore
import Legend from 'cal-heatmap/plugins/Legend';
// @ts-ignore
import CalendarLabel from 'cal-heatmap/plugins/CalendarLabel';

import config from "../config";

const get11MonthsAgo = () => {
  const today = new Date();
  const elevenMonthsAgo = new Date(today);
  elevenMonthsAgo.setMonth(today.getMonth() - 11);
  return elevenMonthsAgo;
}

const get3MonthsAgo = () => {
  const today = new Date();
  const threeMonthsAgo = new Date(today);
  threeMonthsAgo.setMonth(today.getMonth() - 3);
  return threeMonthsAgo;
}

const isSmallScreen = window.innerWidth < 1000;

const WordHeatmap = ({ goal=2000 }) => {
  const heatmapRef = useRef<HTMLDivElement>(null);
  const calInstance = useRef<CalHeatmap>();
  
  useEffect(() => {
    if (!calInstance.current) {
      const cal: CalHeatmap = new CalHeatmap();
    
      cal.paint({
        itemSelector: heatmapRef.current as any,
        range: isSmallScreen ? 4 : 12,
        date: {
          start: isSmallScreen ? get3MonthsAgo() : get11MonthsAgo(),
        },
        // Warm, Cividis, Plasma, Viridis, Turbo, Cool, Reds, Blues, Greens, Oranges, Purples
        scale: { color: { type: 'linear', scheme: 'Cool', domain: [0, goal] } },
        domain: { type: "month", gutter: 5, dynamicDimension: true },
        subDomain: { type: "day", label: { text: null }, radius: 2 },
        data: {
          source: `${config.apiUrl}/api/v1/writing/metrics/heatmap`,
          type: 'json',
          x: 'date',
          y: 'value',
          requestInit: {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          }
        }
      }, 
      [
        [
          Tooltip,
          {
            text: function (date, value, dayjsDate) {
              return (
                (value ? value + ' words' : 'No data') + ' on ' + dayjsDate.format('LL')
              );
            },
          } as any,
        ],
        [
          Legend,
          {
            tickSize: 0,
            width: 150,
            itemSelector: '#ex-year-legend',
            label: 'Words per Day',
          } as any,
        ],
        [
          CalendarLabel,
          {
            width: 30,
            textAlign: 'start',
            text: () => ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'].map((d, i) => (i % 2 == 0 ? '' : d)),
          } as any,
        ],
      ]
    );

    calInstance.current = cal;
  }
  }, [goal]);

  return (
    <div className="rounded-xl border-[1px] border-gray-100 shadow-md max-w-screen-xl p-6 pb-4 mx-auto">
      <div ref={heatmapRef} className="flex justify-center"></div>
      <div id="ex-year-legend" className="flex justify-center"></div>
    </div>
  );
}

export default WordHeatmap;