import React from "react";
import { Link } from "react-router-dom";
import { LuHome, LuPen, LuUsers, LuStickyNote, LuClipboardCheck, LuFileAudio, LuMap, LuScrollText, LuReplaceAll, LuAxe, LuMessagesSquare, LuStar, LuStarHalf, LuExternalLink, LuArrowRight } from "react-icons/lu";

import LandingSection from "../../components/LandingSection";
import FeatureListItem from "../../components/FeatureListItem";

const Landing = () => {
  return (
    <>
      <LandingSection >
        <div className="flex-col justify-center md:flex md:flex-row-reverse">
          <div className="p-0 sm:pl-10 lg:pl-36">
            <img src="/logo512.png" alt="Landing" className="w-64 h-64 mx-auto" />
          </div>
          <div className="pt-7">
            <h1 className="text-4xl font-bold pb-10 text-center">Better Writing from Day One</h1>
            <p className="text-lg text-center pb-6">
              The most trusted home base for Authors and Creatives.
            </p>
            <div className="flex flex-col md:flex-row items-center">
              <Link to="/register">
                <button className="mt-4 py-2 px-4 bg-[#EE6352] text-white font-bold rounded-lg">
                  Get Started - entirely free!
                </button>
              </Link>

              <Link to="/pricing" className="md:pl-16">
                <button className="mt-4 py-2 px-12 bg-white text-[#EE6352] font-bold rounded-lg border-[1px] border-[#EE6352] transition-all">
                  Purchase
                </button>
              </Link>
            </div>
          </div>
        </div>
      </LandingSection>
      <LandingSection bgClass="bg-gradient-to-br from-[#279AF1] to-[#114b5f]">
        <h1 className="text-4xl font-bold pb-20 text-center text-white">
          Features from start to publication
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-start gap-y-8 max-w-screen-lg">
          <FeatureListItem
            title="Better Writing; Anywhere"
            description="Our platform is designed to help you write better from day one on any device."
            icon={<LuPen className="text-5xl text-white" />} />
          <FeatureListItem
            title="Notes and TODOs to Stay Organized"
            description="Keep track of your notes and todos in one place, create them on the fly, and check them while writing."
            icon={<LuClipboardCheck className="text-5xl text-white" />} />
          <FeatureListItem
            title="Manage Characters"
            description="Keep track of your characters, their relationships, and their arcs. Stay up to date with in-document links."
            icon={<LuUsers className="text-5xl text-white" />} />
          <FeatureListItem
            title="Convert to Audiobook"
            description="ShipWriter will read your stories back to you, so you can hear the flow."
            icon={<LuFileAudio className="text-5xl text-white" />} />
          <FeatureListItem
            title="Voice and Articulation"
            description="Learn new and popular sentence structures, examine your work, and develop your own writing voice."
            icon={<LuScrollText className="text-5xl text-white" />} />
          <FeatureListItem
            title="Word Map"
            description="Discover and track the emotion-evoking words in your writing."
            icon={<LuMap className="text-5xl text-white" />} />
          <FeatureListItem
            title="Share with Beta Readers"
            description="Share your writing and get organized feedback. Stories can even be shared in audiobook format."
            icon={<LuReplaceAll className="text-5xl text-white" />} />
          <FeatureListItem
            title="Convert to Manuscript"
            description="Convert your writing to a manuscript format instantly and submit it."
            icon={<LuAxe className="text-5xl text-white" />} />
        </div>
      </LandingSection>
      <LandingSection bgClass="bg-gray-100">
        <h1 className="text-4xl font-bold pb-10 text-center">
          What our users are saying
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-start gap-x-8 gap-y-12 max-w-screen-lg">
          <div className="flex flex-col justify-between">
            <div className="px-6 md:px-0">
              <h1 className="italic text-4xl pb-2 text-center">"Revolutionary Writing Flexibility."</h1>
              <p className="pb-2">As a seasoned author, I am constantly on the lookout for tools that can enhance my writing process. ShipWriter has revolutionized the way I approach my work. The ability to write anywhere, whether on my laptop, tablet, or even my phone, means I can capture inspiration whenever it strikes. The seamless syncing across devices ensures I never lose my train of thought. The notes and to-do lists are lifesavers, allowing me to keep track of plot points, character details, and deadlines effortlessly. ShipWriter has truly become my portable writing studio, and I can’t imagine going back to my old setup.</p>
              <p className="text-right text-lg font-bold italic">- Alex Jones, Author</p>
            </div>
            <div className="flex items-center justify-center text-4xl">
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
            </div>
          </div>
          <div>
          <div className="px-6 md:px-0">
              <h1 className="italic text-4xl pb-2 text-center">"From Page to Audiobook with Ease."</h1>
              <p className="pb-2">One of the standout features of ShipWriter is the audiobook transcription functionality. As someone who likes to read my work aloud to catch inconsistencies and improve flow, this tool is invaluable. The transcription is accurate and saves me hours of manual recording. It also allows me to share audio versions of my drafts with my beta readers, who can provide feedback in a format that's easy for them to consume. This feature alone makes ShipWriter worth the investment.</p>
              <p className="text-right text-lg font-bold italic">- Coraline Lott, Author</p>
            </div>
            <div className="flex items-center justify-center text-4xl">
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
            </div>
          </div>
          <div>
          <div className="px-6 md:px-0">
              <h1 className="italic text-4xl pb-2 text-center">"Perfect for Beta Reading and Feedback."</h1>
              <p className="pb-2">Gathering feedback from beta readers has always been a crucial part of my writing process, but it can be cumbersome to manage. ShipWriter’s beta reading functionality has transformed this experience. I can track feedback efficiently, categorize comments, and see at a glance which areas of my manuscript need attention. The ability to directly integrate feedback into my drafts has streamlined revisions and improved the overall quality of my work. My beta readers have also appreciated the user-friendly interface, making the process smooth for everyone involved.</p>
              <p className="text-right text-lg font-bold italic">- Shane Gorr, Youtuber</p>
            </div>
            <div className="flex items-center justify-center text-4xl">
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStarHalf /></span>
            </div>
          </div>
          <div>
          <div className="px-6 md:px-0">
              <h1 className="italic text-4xl pb-2 text-center">"Comprehensive Writing Suite."</h1>
              <p className="pb-2">ShipWriter is not just a writing tool; it's a comprehensive suite designed for authors at any stage of their career. From the moment an idea strikes to the final manuscript, ShipWriter supports every step of the writing process. The intuitive interface, powerful features, and seamless integration across all my devices make it an indispensable part of my writing journey. Whether you’re a seasoned author or just starting, ShipWriter offers everything you need to bring your stories to life. Highly recommended!</p>
              <p className="text-right text-lg font-bold italic">- John Walker, Playwright</p>
            </div>
            <div className="flex items-center justify-center text-4xl">
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
              <span><LuStar /></span>
            </div>
          </div>
        </div>
      </LandingSection>
      <LandingSection bgClass="bg-white">
        <h1 className="text-4xl font-bold pb-10 text-center">Three Steps to Greatness</h1>
        <div className="flex flex-col md:flex-row max-w-screen-lg">
          <div className="flex flex-col max-w-72 mb-10">
            <img src="/checkmark.png" alt="register" className="w-48 h-48 mx-auto bg-[#cbf3e8] rounded-full" />
            <h1 className="text-2xl font-bold pb-5 text-center">1. Sign up for free</h1>
            <p className="text-lg text-center">
              Create an account and start writing your next bestseller.
            </p>
          </div>
          <span className="hidden md:block my-16 text-5xl text-gray-400"><LuArrowRight /></span>
          <div className="flex flex-col max-w-72 mb-10">
            <img src="/task.png" alt="register" className="w-48 h-48 mx-auto" />
            <h1 className="text-2xl font-bold pb-5 text-center">2. Collect feedback</h1>
            <p className="text-lg text-center">
              Share your writing with beta readers and collaborators, and receive feedback directly within the platform.
            </p>
          </div>
          <span className="hidden md:block my-16 text-5xl text-gray-400"><LuArrowRight /></span>
          <div className="flex flex-col max-w-72 mb-10">
            <div className="p-3 mx-auto bg-[#44da92] rounded-full">
              <img src="/cube.png" alt="register" className="w-[10.5rem] h-[10.5rem]" />
            </div>
            <h1 className="text-2xl font-bold pb-5 text-center">3. Publish Anywhere</h1>
            <p className="text-lg text-center">
              Submit your work to magazines, editors, and self-publishing sites all in a few clicks.
            </p>
          </div>
        </div>
        <Link to="/register" className="mx-auto">
          <button className="mt-10 py-2 px-4 bg-[#1A936F] text-white text-2xl font-bold rounded-lg">
            Get started
          </button>
        </Link>
      </LandingSection>
      <LandingSection bgClass="bg-[#a8cfc4]">
        <div>
          <h1 className="text-4xl font-bold pb-10 text-center">Frequently Asked Questions</h1>
          <div className="grid grid-cols-1 sm:grid-cols-2 justify-items-start gap-x-8 gap-y-12 max-w-screen-lg">
            <div className="px-6">
              <h1 className="text-2xl font-bold pb-2">How much does ShipWriter cost?</h1>
              <p>ShipWriter offers a free tier with everything you need to build and write your stories. Our premium and pro plans offer additional features such as audiobook transcription, character management, and beta reading integration. For more information, please visit our <Link className="text-lg font-bold flex hover:underline" to="/pricing">Pricing Page. <LuExternalLink className="m-[5px]" /></Link></p>
            </div>
            <div className="px-6">
              <h1 className="text-2xl font-bold pb-2">Is ShipWriter available on mobile devices?</h1>
              <p>Yes! Our best-in-class website works perfectly both on your computers and your mobile, tablet devices, or anywhere you write.</p>
            </div>
            <div className="px-6">
              <h1 className="text-2xl font-bold pb-2">Can I share my writing with others?</h1>
              <p>Yes, ShipWriter allows you to share your writing with beta readers, editors, and collaborators. You can control the level of access they have and receive feedback directly within the platform.</p>
            </div>
            <div className="px-6">
              <h1 className="text-2xl font-bold pb-2">How does the audiobook transcription feature work?</h1>
              <p>ShipWriter uses advanced speech-to-text technology to transcribe your writing into an audiobook format. You can listen to your work read aloud, share it with others, and receive feedback in an audio format.</p>
            </div>
          </div>
        </div>
      </LandingSection>
      <LandingSection bgClass="bg-gradient-to-br from-gray-600 to-black">
      <div className="flex-col justify-center">
          <div className="pt-7 px-10">
            <h1 className="text-4xl md:text-5xl font-bold pb-10 text-white text-center">Your next masterpiece starts here</h1>
            <div className="flex flex-col md:flex-row items-center">
              <Link to="/register" className="mx-auto">
                <button className="mt-10 py-2 px-4 bg-[#1A936F] text-white text-2xl font-bold rounded-lg">
                  Try for free today
                </button>
              </Link>
            </div>
          </div>
        </div>
      </LandingSection>
      <div className="flex flex-col items-center justify-center h-full py-4 md:py-10 bg-white">
        <div className="flex">
          <img src="/logo192.png" alt="Landing" className="w-20 h-20 mx-auto" />
          <h1 className="text-4xl font-play m-auto pl-4">ShipWriter</h1>
        </div>
      </div>
    </>
  );
}
//bg-[#114B5F]
export default Landing;

// https://coolors.co/114b5f-1a936f-88d498-a8cfc4-f3e9d2
// https://coolors.co/114b5f-ee6352-1a936f-513b3c-c1eeff

//a8cfc4

// 44da92

// #114B5F
// #88D498
// #F3E9D2

// 0d2149

//#EE6352

//6400ff

// 279AF1