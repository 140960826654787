import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { LuCamera } from "react-icons/lu";

import config from "../../config";

const Avatar = ({ imageUrl, setImageUrl }) => {
  const [image, setImage] = useState(null);
  const { id } = useParams();

  const uploadImage = async (imageData) => {
    console.log(image);

    const formData = new FormData();
    formData.append("image", imageData, "avatar." + imageData.type.split("/")[1]);

    try {
      const res = await fetch(`${config.apiUrl}/api/v1/characters/${id}/image`, {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error("Failed to upload image");
      }

      const resBody = await res.json();
      console.log(resBody);
    } catch (err) {
      console.error(err);
    }
  }

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImageUrl(URL.createObjectURL(e.target.files[0]));
    uploadImage(e.target.files[0]);
  }

  return (
    <div className="flex flex-col items-center py-5 min-h-96 gap-y-8">
      {imageUrl && <img
        src={imageUrl}
        alt="Avatar"
        className="w-96 h-full rounded-xl object-cover justify-self-center" />}
      <label className="ml-4 my-auto flex items-center cursor-pointer">
        <div className="bg-gray-100 hover:bg-gray-300 p-4 rounded-full border-[1px] transition-all">
          <LuCamera className="text-4xl" />
        </div>
        <input
          className="hidden"
          type="file"
          accept="image/*"
          onChange={handleImageChange}
        />
      </label>
    </div>
  )
}

export default Avatar;