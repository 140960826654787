import React, { useState } from "react";
import { Tooltip } from "react-tooltip";
import { LuX, LuInfo } from "react-icons/lu";

const ChipInput = ({ label, chips, setChips, tooltipText='' }) => {
  const [text, setText] = useState("");
  const [validationError, setValidationError] = useState("");

  function removeChip(chipToRemove) {
    // filtering out the chip that the user wants to remove
    const updatedChips = chips.filter((chip) => chip !== chipToRemove);
    setChips(updatedChips);
  }

  function handlePressEnter(e) {
    // don't submit the form if the user presses 'Enter'
    if (e.key === "Enter" || e.key === ",") e.preventDefault();
    // return if the user pressed a key that is not 'Enter', or the user hasn't typed anything
    if ((e.key !== "Enter" && e.key !== ",") || !text) return;
    // need to show error if the user tries to add the same input more than once
    if (chips.includes(text)) {
      return setValidationError("Cannot add the same input more than once");
    }
    // adding the input value to chips array
    setChips((prevState) => [...prevState, e.target.value]);
    // clearing the input box
    setText("");
    // clearing error message
    setValidationError("");
  }

  return (
    <div>
      <label htmlFor="chips" className="px-4 flex justify-between">
        {label} {tooltipText && <a data-tooltip-id="chip-tooltip" data-tooltip-html={tooltipText}>
          <LuInfo className="ml-4 text-xl text-gray-400 cursor-pointer" />
        </a>}
      <Tooltip id="chip-tooltip" place="left" />
      </label>
      <div className="flex flex-wrap p-1 items-center bg-gray-100 rounded-3xl focus:bg-white">
        <ul className="flex list-none space-x-1">
          {chips.map((chip) => (
            <li key={chip} className="flex items-center px-2 py-1 ml-1 space-x-1 bg-[#a8cfc4] rounded-full">
              <span>{chip}</span>
              <LuX
                className="text-xl text-[#a8cfc4] bg-[#1A936F] rounded-full cursor-pointer"
                onClick={() => removeChip(chip)} tabIndex={0} />
            </li>
          ))}
        </ul>
        <input
          className="grow bg-transparent border-none focus:outline-0 focus:ring-0 rounded-full"
          id="chips"
          type="text"
          name={label}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handlePressEnter}
        />
      </div>
      {validationError && <p className="mt-4 text-red-400">{validationError}</p>}
    </div>
  );
}

// mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-transparent focus:bg-white focus:ring-0

export default ChipInput;