import React, { useState, useEffect } from "react";
import { LuX } from "react-icons/lu";

type Props = {
  isOpen: boolean;
  hasCloseBtn: boolean;
  onClose?: () => void;
  children: React.ReactNode;
};

const Modal = ({ isOpen, hasCloseBtn, onClose, children }) => {
  const handleCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  // Close modal when clicking outside the modal content
  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      handleCloseModal();
    }
  };

  // Close modal on Escape key press
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    document.addEventListener('keydown', handleEscape);
    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <div 
      onClick={handleOverlayClick}
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg relative w-full max-w-lg">
        {hasCloseBtn && <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
          onClick={onClose}
          >
          <LuX className="text-2xl" />
        </button>}
      {children}
      </div>
    </div>
  );
};

export default Modal;