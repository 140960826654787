import React, { useState } from "react";
import { LuCheck } from "react-icons/lu";

import ChipInput from "../../components/ChipInput";

const OverviewForm = ({ name, setName, attributes, setAttributes, tags, setTags, imageUrl }) => {
  const [showAddAttribute, setShowAddAttribute] = useState(false);
  const [newAttributeName, setNewAttributeName] = useState("");
  const [newAttributeValue, setNewAttributeValue] = useState("");

  const handleAddAttribute = () => {
    const newAttributes = { ...attributes };
    newAttributes[newAttributeName] = newAttributeValue;
    setAttributes(newAttributes);

    setNewAttributeName("");
    setNewAttributeValue("");
    setShowAddAttribute(false);
  }

  const handleAttributeChange = (key, value) => {
    const newAttributes = { ...attributes };
    newAttributes[key] = value;
    setAttributes(newAttributes);
  }

  return (
    <div
      className="p-5 pt-20"
    >
      <div className="my-auto">
        <div className="flex justify-center pb-10">
          <h1 className="text-2xl font-bold">Character Overview</h1>
        </div>
        {imageUrl && <img
          src={imageUrl}
          alt="Avatar"
          className="w-96 h-full mb-10 rounded-xl object-cover mx-auto" />}
        <label>
          <span className="pl-4">Name</span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </label>
        <ChipInput label="Tags" chips={tags} setChips={setTags} tooltipText="Tags are useful for referencing your characters<br />from your writing. Any tags you specify here<br />will automatically be identified in your text<br />and linked back to this page.<br /><br />NOTE: Capitalization is important." />
        {/* <label>
          <span className="pl-4">Tags</span>
          <input
            className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
            required
            type="text"
            name="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
          />
        </label> */}
        <div className="flex justify-center py-10">
          <h1 className="text-xl font-bold">Character Attributes</h1>
        </div>
        {attributes && Object.keys(attributes).map((key, index) => (
          <label>
            <span className="pl-4">{key}</span>
            <input
              className="mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
              required
              type="text"
              name={key}
              value={attributes[key]}
              onChange={(e) => handleAttributeChange(key, e.target.value)}
            />
          </label>
        ))}

        {showAddAttribute && (
          <form 
            className="grid grid-cols-1 md:grid-cols-5 gap-4 w-full"
            onSubmit={handleAddAttribute}>
            <label className="md:col-span-2">
              <span className="pl-4">Attribute Name</span>
              <input
                className="md:mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                required
                type="text"
                name="attributeName"
                value={newAttributeName}
                onChange={(e) => setNewAttributeName(e.target.value)}
              />
            </label>
            <label className="md:col-span-2">
              <span className="pl-4">Attribute Value</span>
              <input
                className="md:mb-10 mt-1 w-full block text-black rounded-full border-transparent shadow-sm bg-gray-100 focus:border-gray-500 focus:bg-white focus:ring-0"
                required
                type="text"
                name="attributeValue"
                value={newAttributeValue}
                onChange={(e) => setNewAttributeValue(e.target.value)}
              />
            </label>
            <button
              className="py-2 px-5 mt-[1.67rem] max-h-11 bg-[#1A936F] text-white font-bold text-center rounded-full transition-all"
              type="submit"
            >
              <LuCheck className="mx-auto text-3xl" />
            </button>
          </form>
        )}

        {!showAddAttribute && <div className="flex w-full">
          <button
            className="py-2 px-5 mt-5 mx-auto bg-[#1A936F] text-white font-bold rounded-full transition-all"
            onClick={() => setShowAddAttribute(true)}
          >
            Add Character Attribute
          </button>
        </div>}
      </div>
    </div>
  )
};

export default OverviewForm;