import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LuUserPlus } from "react-icons/lu";

import CharacterCard from "./CharacterCard";

import backendRequest from "../../utils/api";

import config from "../../config";

const CharacterList = () => {
  const [loading, setLoading] = useState(true);
  const [characters, setCharacters] = useState([]);
  const navigate = useNavigate();

  const handleNewCharacter = async () => {
    try {
      const resBody = await backendRequest('POST', '/api/v1/characters', {
        name: "[New Character]",
        attributes: {},
        details: "",
        tags: [],
        vlees: {
          want: '',
          need: '',
          drive: '',
          secrets: [],
          flaw: '',
          strength: '',
          cost: '',
        }
      });

      navigate(`/characters/${resBody._id}`);
    } catch (err) {
      console.error(err);
    }
  };

  const loadCharacters = async () => {
    try {
      const resBody = await backendRequest('GET', '/api/v1/characters');

      setCharacters(resBody);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    loadCharacters().then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  return (
    <div className="h-full min-h-screen w-full bg-gray-100">
      <div className="w-full flex justify-end sm:justify-normal">
        <button 
          onClick={handleNewCharacter}
          className="mx-8 mt-4 bg-[#1A936F] text-gray-100 rounded-lg">
          <div className="flex">
            <span className="p-1.5">
              <LuUserPlus className="text-lg" />
              </span> 
            <h1 className="text-lg pr-2">New Character</h1>
          </div>
        </button>
      </div>
      <div className="p-4 pt-1 flex flex-row flex-wrap">
        {characters.map((character) => (
          <CharacterCard key={character._id} character={character} />
        ))}
      </div>
    </div>
  )
}

export default CharacterList;