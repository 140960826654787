import ReactQuill, { Quill } from 'react-quill';

import config from '../config';

const Link = Quill.import('formats/link') as any;

type Tag = {
  name: string,
  url: string
}

export class ClickableLink extends Link {
  static create(value) {
    const node = super.create(value);
    node.setAttribute('href', Link.sanitize(value));
    node.setAttribute('rel', '');
    node.setAttribute('target', '_self');
    node.setAttribute('contenteditable', 'true');
    // Remove contenteditable when hovering,
    // readd it when not
    node.addEventListener('mouseenter', () => {
      node.setAttribute('contenteditable', 'false');
    });
    node.addEventListener('mouseleave', () => {
      node.setAttribute('contenteditable', 'true');
    });
    return node;
  }
}

export class WordCounter {
  quill: any;
  container: any;

  constructor(quill, options) {
    this.quill = quill;
    this.container = document.getElementById(options.containerId);
    quill.on(Quill.events.TEXT_CHANGE, this.update.bind(this));
    this.update();  // Account for initial contents
  }

  calculate() {
    const text = this.quill.getText().trim();
    if (text.length === 0) {
      return 0;
    }
    return text.split(/\s+/).length;
  }

  update() {
    const length = this.calculate();
    this.container.innerText = length;
    console.log();
  }
}

const Inline = Quill.import('blots/inline') as any;

export class ClickableName extends Inline {
  static blotName = 'clickable-name';
  static tagName = 'a';

  static create(value) {
    const node = super.create(value);
    node.setAttribute('data-name', value.name);

    node.setAttribute('href', Link.sanitize(value.url));
    node.setAttribute('rel', '');
    node.setAttribute('target', '_self');
    node.setAttribute('contenteditable', 'true');
    // Remove contenteditable when hovering,
    // readd it when not
    node.addEventListener('mouseenter', () => {
      node.setAttribute('contenteditable', 'false');
    });
    node.addEventListener('mouseleave', () => {
      node.setAttribute('contenteditable', 'true');
    });
    return node;
  }

  static formats(node) {
    return {
      name: node.getAttribute('data-name'),
      url: node.getAttribute('href')
    };
  }

  format(name, value) {
    if (name === 'clickable-name' && value) {
      this.domNode.setAttribute('data-name', value.name);
      this.domNode.setAttribute('href', value.url);
    } else {
      super.format(name, value);
    }
  }
}

export class ClickableNameModule {
  quill: any;
  options: any;
  tags: Tag[] = [];

  constructor(quill, options) {
    this.quill = quill;
    this.options = options;
    quill.on(Quill.events.TEXT_CHANGE, this.onTextChange.bind(this));
    this.loadTags();
  }

  onTextChange(delta, oldDelta, source) {
    if (source !== 'user') return;

    if (this.tags.length === 0) return;

    let text = this.quill.getText().trim();
    if (text.length === 0) return;

    if (text.length > 1000) {
      text = text.slice(-1000);
    }

    let match;
    const matches = [];

    this.tags.forEach((tag) => {
      const regex = new RegExp(`\\b${tag.name}\\b`, 'g');
      while ((match = regex.exec(text)) !== null) {
        matches.push({ tag, index: match.index, length: match[0].length, name: match[0] });
      }
    });
    
    matches.forEach((match) => {
      const [index, length] = [match.index, match.length];
      this.quill.formatText(index, length, 'clickable-name', { name: match.name, url: match.tag.url });
    });
  }

  loadTags() {
    fetch(`${config.apiUrl}/api/v1/characters/tags`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
      },
    })
    .then((res) => res.json())
    .then((tags) => {
      this.tags = tags
    });
  }
}