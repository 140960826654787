import React from "react";
import { useParams } from "react-router-dom";

type Params = {
  type: 'creative' | 'industry' | 'enterprise';
}

const Purchase = () => {
  const { type } = useParams();

  return (
    <div>
      <h1>Purchase {type}</h1>
    </div>
  );
}

export default Purchase;