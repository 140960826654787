import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { LuGem, LuPenSquare } from "react-icons/lu";

import PaperEditor from "../../components/Paper";

import { useDebounce } from "../../utils/debounce";
import backendRequest from "../../utils/api";

import OverviewForm from "./OverviewForm";
import VleesForm from "./VleesForm";
import DesignForm from "./DesignForm";

const Character = () => {
  const { id } = useParams();

  // Fetch the writing with the given ID
  const [name, setName] = useState("");
  const [details, setDetails] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [vlees, setVlees] = useState({});
  const [tags, setTags] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [voiceUrl, setVoiceUrl] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);

  const saveCharacter = async () => {
    try {
      await backendRequest('PUT', `/api/v1/characters/${id}`, {
        name,
        details: JSON.stringify(details),
        attributes,
        vlees,
        tags
      });
    } catch (err) {
      console.error(err);
    }
  }

  const debouncedSaveCharacter = useDebounce(() => saveCharacter());

  const updateName = (name) => {
    setName(name);
    debouncedSaveCharacter();
  }

  const updateAttributes = (newAttributeObj) => {
    setAttributes(newAttributeObj);
    debouncedSaveCharacter();
  }

  const updateTags = (newTags) => {
    setTags(newTags);
    debouncedSaveCharacter();
  }

  const updateVlees = (newVlees) => {
    setVlees(newVlees);
    debouncedSaveCharacter();
  }

  const parseAndSetDetails = (newDetails) => {
    try {
      const parsedContent = JSON.parse(newDetails);
      setDetails(parsedContent.ops);
      console.log(parsedContent.ops);
    } catch (err) {
      console.error(err);
      setDetails(newDetails);
    }
  }

  const loadCharacter = async (id: string) => {
    try {
      const resBody = await backendRequest('GET', `/api/v1/characters/${id}`);
      setName(resBody.name);
      parseAndSetDetails(resBody.details);
      setAttributes(resBody.attributes);
      setVlees(resBody.vlees);
      setTags(resBody.tags);
      setImageUrl(resBody.imageUrl);
      setVoiceUrl(resBody.voiceUrl);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadCharacter(id);
  }, []);

  return (
    <div className="h-full min-h-screen bg-gray-100">
      <div className="p-4 flex">
        <LuPenSquare className="text-gray-400 text-xl mt-3 -scale-x-100" />
        <input 
          type="text"
          value={name}
          placeholder="Legendary Person's Name..."
          onChange={(e) => updateName(e.target.value)}
          className="text-black-500 font-semibold text-xl border-none bg-inherit focus:outline-0 focus:ring-0 outline-none">
        </input>
      </div>
      <div className="p-4 pt-0 h-full">
        {/* <textarea
          className="w-full h-full py-10 px-12 bg-white border-white focus:border-white focus:outline-0 focus:ring-0 outline-none rounded-lg"
          placeholder="Once upon a midnight dreary..."
          value={content}
          onChange={updateContent}></textarea> */}
          <Tabs>
            <TabList className={'flex rounded-full justify-center p-1 pb-4 cursor-pointer'}>
              <Tab
                className={`rounded-l-full py-2 pr-3 pl-4 border-[1px] outline-none ${selectedIndex === 0 ? 'bg-[#1A936F] text-white border-[#1A936F]' : 'bg-white hover:bg-gray-100 border-gray-200'}`}
                onClick={() => setSelectedIndex(0)}
                >Overview</Tab>
              <Tab
                className={`py-2 px-3 border-[1px] border-l-0 outline-none ${selectedIndex === 1 ? 'bg-[#1A936F] text-white border-[#1A936F]' : 'bg-white hover:bg-gray-100 border-gray-200'}`}
                onClick={() => setSelectedIndex(1)}
                >Details</Tab>
              <Tab
                className={`py-2 px-3 border-[1px] border-l-0 outline-none ${selectedIndex === 2 ? 'bg-[#1A936F] text-white border-[#1A936F]' : 'bg-white hover:bg-gray-100 border-gray-200'}`}
                onClick={() => setSelectedIndex(2)}
                >Vlees</Tab>
              <Tab
                className={`flex rounded-r-full py-2 pl-3 pr-4 border-[1px] border-l-0 outline-none ${selectedIndex === 3 ? 'bg-[#1A936F] text-white border-[#1A936F]' : 'bg-white hover:bg-gray-100 border-gray-200'}`}
                onClick={() => setSelectedIndex(3)}
                ><LuGem className="mt-[3.5px] mr-1" /> Design</Tab>
            </TabList>

        <div className="w-full lg:max-w-4xl h-full min-h-screen py-10 px-2 sm:px-12 mx-auto font-roboto bg-white border-white outline-none rounded-lg">
            {/* Overview */}
            <TabPanel>
              <div>
                <OverviewForm 
                  name={name}
                  setName={updateName}
                  attributes={attributes}
                  setAttributes={updateAttributes}
                  tags={tags}
                  setTags={updateTags}
                  imageUrl={imageUrl} />
              </div>
            </TabPanel>

            {/* Details */}
            <TabPanel>
              <div className="p-5 pt-20">
                <div className="flex justify-center pb-10">
                  <h1 className="text-2xl font-bold">Character Details</h1>
                </div>
                <PaperEditor 
                  content={details}
                  setContent={setDetails}
                  debouncedSaveChanges={debouncedSaveCharacter}
                  placeholder="Write the details of your character here..."
                />
              </div>
            </TabPanel>

            {/* Vlees */}
            <TabPanel>
              <div>
                <VleesForm 
                  vlees={vlees}
                  setVlees={updateVlees} />
              </div>
            </TabPanel>

            {/* Design */}
            <TabPanel>
              <div>
                <DesignForm imageUrl={imageUrl} setImageUrl={setImageUrl} voiceUrl={voiceUrl} />
              </div>
            </TabPanel>
        </div>
          </Tabs>
      </div>
    </div>
  )
}

export default Character;