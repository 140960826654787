import React from "react";
import { useState } from "react";
import { LuCheck, LuAxe } from "react-icons/lu";

const NewWritingCard = ({ setVisible }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  return (
    <div 
      onBlur={() => setVisible(false)}
      className="bg-white shadow-md rounded-lg m-4 w-full sm:w-1/2 md:w-1/3 lg:w-1/4">
      <div className="h-full flex">
        <div className="grow flex flex-col align-top h-full pl-2">
          <input 
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Your amazing novel title..."
            className="h-1/2 w-auto p-2 text-black-500 font-semibold text-xl border-none focus:outline-0 focus:ring-0 outline-none">
          </input>
          <input 
            type="text"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="(Optional) Jot down a few sentences"
            className="p-2 w-auto text-black-500 border-none focus:outline-0 focus:ring-0 outline-none">
          </input>
        </div>
        <div className="flex flex-col h-full">
          <button className="bg-white hover:bg-green border-[1px] border-green text-green hover:text-white font-bold text-3xl py-2 px-4 rounded-lg rounded-b-none rounded-l-none h-1/2 transition-all">
            <span>
              <LuCheck />
            </span>
          </button>
          <button 
            onClick={() => setVisible(false)}
            className="bg-white hover:bg-red-500 border-[1px] border-red-500 text-red-500 hover:text-white text-3xl py-2 px-4 rounded-lg rounded-t-none rounded-l-none h-1/2 transition-all">
            <span>
              <LuAxe />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewWritingCard;