import React, {useState, useEffect} from "react";
import { useParams } from "react-router-dom";

import PaperEditor from "../../components/Paper";

import { useDebounce } from "../../utils/debounce";
import backendRequest from "../../utils/api";

import config from "../../config";

const Writing = () => {
  const { id } = useParams();

  // Fetch the writing with the given ID
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const saveWriting = async () => {
    try {
      await backendRequest('PUT', `/api/v1/writing/${id}`, {
        title,
        content: JSON.stringify(content),
        wordCount: document.getElementById("wordCounter").innerText,
        userTimestamp: new Date().toISOString(),
      });
    } catch (err) {
      console.error(err);
    }
  }

  const debouncedSaveWriting = useDebounce(() => saveWriting());

  const updateTitle = (e) => {
    setTitle(e.target.value);
    debouncedSaveWriting();
  }

  // {"ops":[{"attributes":{"italic":true},"insert":"\"The flesh falls away, but god's glory remains\" - "},{"insert":"Techno"},{"attributes":{"clickable-name":{"name":"Nathan","url":"/characters/669f5811f2f40249c8d22164"}},"insert":"-bionic Religious sect."},{"insert":"\n\n"},{"attributes":{"clickable-name":{"name":"Nathan","url":"/characters/669f5811f2f40249c8d22164"}},"insert":"The religion Nathan"},{"insert":"'s parents raised him in made a point of replacing their children's body parts with machinery before they reached adolescence. The reasoning behind this was almost sound; if god created the world in the first day, then the world and everything on it was Most Holy. This holiness extended to the metals in the ground, and thus made bionic modification a Most Holy ritual of receiving god's blessing. Of course, not all metals in the ground were worthy, and as such the metal for biomechanics came from a select stoc"},{"attributes":{"clickable-name":{"name":"Nate","url":"/characters/669f5811f2f40249c8d22164"}},"insert":"k and pre-blessed by a high priest. "},{"insert":"\n\n"},{"attributes":{"clickable-name":{"name":"Nate","url":"/characters/669f5811f2f40249c8d22164"}},"insert":"Nate"},{"insert":" on the other hand, naturally thought this was all horse shit, and resented and continues to resent his parents for what they did to him. Even though he's now part machine, he still deeply loves the human, fleshy parts of himself. So much so that he learned to write with his left hand, even though his right-handedness and mechanics outperformed his left-hand writing in every way.\n"}]}

  const loadWriting = async (id: string) => {
    try {
      const resBody = await backendRequest('GET', `/api/v1/writing/${id}`);

      setTitle(resBody.title);

      try {
        const parsedContent = JSON.parse(resBody.content);
        setContent(parsedContent.ops);
      } catch (err) {
        console.error(err);
        setContent(resBody.content);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    loadWriting(id);
      // .then((content) => loadAndUpdateCharacterTags(content));
  }, [id]);

  return (
    <div className="h-full min-h-screen bg-gray-100">
      <div className="p-4">
        <input 
          type="text"
          value={title}
          placeholder="Your amazing new novel title here..."
          onChange={updateTitle}
          className="text-black-500 font-semibold text-xl border-none bg-inherit focus:outline-0 focus:ring-0 outline-none">
        </input>
      </div>
      <div className="p-4 h-full">
        {/* <textarea
          className="w-full h-full py-10 px-12 bg-white border-white focus:border-white focus:outline-0 focus:ring-0 outline-none rounded-lg"
          placeholder="Once upon a midnight dreary..."
          value={content}
          onChange={updateContent}></textarea> */}
        <div className="w-full lg:max-w-4xl h-full min-h-screen py-10 px-2 sm:px-12 mx-auto font-roboto bg-white border-white outline-none rounded-lg">
          <PaperEditor 
            content={content}
            setContent={setContent}
            debouncedSaveChanges={debouncedSaveWriting}
            />
        </div>
      </div>
    </div>
  )
}

export default Writing;